export const bartenders = {
  category: 'Bartenders',
  vendors: [
    {
      name: 'Vendor 1',
      website: 'https://www.google.com',
    },
    {
      name: 'Vendor 2',
      website: 'https://www.google.com',
    },
    {
      name: 'Vendor 3',
      website: 'https://www.google.com',
    },
  ],
};

export const caterers = {
  category: 'Caterers',
  vendors: [
    {
      name: 'Eko African Cuisine',
      website: 'https://www.instagram.com/ekorestaurantindy/',
    },
    {
      name: 'Fire & Desire LLC',
      website: 'https://www.instagram.com/fire_desire_llc/',
    },
    {
      name: 'ForkedUp Catering',
      website: 'https://www.instagram.com/forkedupcatering/',
    },
    {
      name: 'Opa!',
      website: 'https://opaavonin.com/',
    },
  ],
};

export const djs = {
  category: 'Djs',
  vendors: [
    {
      name: 'The Blendmaster',
      website: 'https://www.instagram.com/theblendmaster/',
    },
    {
      name: 'Mike Lowery',
      website: 'https://www.instagram.com/_therealmikelowery247',
    },
    {
      name: 'Shawndale Walls',
      website: 'https://www.djsd1.com/',
    },
    {
      name: 'Table Manners Productions LLC',
      website: 'https://www.tablemannersproductions.com/',
    },
  ],
};

export const decorators = {
  category: 'Decorators',
  vendors: [
    {
      name: 'Bhatta Boom Photo Booth',
      website: 'https://www.bhattaboomphotobooth.com/',
    },
    {
      name: 'Festive Fairy',
      website: 'https://www.instagram.com/festivefairy__/',
    },
    {
      name: 'KolorPop Designz',
      website: 'https://www.kolorpopdesignz.com/',
    },
  ],
};

export const eventPlanners = {
  category: 'Event Planners',
  vendors: [
    {
      name: 'LoveLight Events LLC',
      website: 'https://lovelighteventsllc.com/',
    },
    {
      name: 'Thee Craft Castle',
      website:
        'https://www.facebook.com/TheeBryantsCraftCastle?mibextid=LQQJ4d',
    },
    {
      name: 'Tori Leigh Events',
      website: 'https://www.torileighevents.com/',
    },
    {
      name: `Tribute Events`,
      website: 'https://www.facebook.com/tributevents',
    },
  ],
};

export const hotels = {
  category: 'Hotels',
  vendors: [
    {
      name: 'Fairfield Inn & Suites Indianapolis Carmel',
      website:
        'https://www.marriott.com/en-us/hotels/indml-fairfield-inn-and-suites-indianapolis-carmel/overview/',
    },
    {
      name: 'Hilton Garden Inn Fishers',
      website:
        'https://www.hilton.com/en/hotels/indfhgi-hilton-garden-inn-indianapolis-northeast-fishers/?SEO_id=GMB-AMER-GI-INDFHGI&y_source=1_MjA4NTM0NS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D',
    },
    {
      name: 'Home2 Suites Fishers',
      website:
        'https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=INDFEHT&arrivalDate=2023-02-23&departureDate=2023-02-25&room1NumAdults=2&inputModule=HOTEL_SEARCH&viewPackagesAndPromotionsRate=true&WT.mc_id=zLADA0WW1XX2OLX3DA4MS5MS6_INDFEHT7_298227037_&adType=FL&dsclid=50718054857080832&dclid=CjkKEQiAn4SeBhCehonomNbViN4BEiQAIzgWy9yjyZZm4H-0kF6EcIveqhBYElXkKHUisY6EqjVFfmTw_wcB&hmGUID=a5e61e8a-71f7-47a7-1e3f-6630e0001df8',
    },
  ],
};

export const photoVideo = {
  category: 'Photographers',
  vendors: [
    {
      name: 'COA Productions',
      website: 'https://www.coaproduction.com/',
    },
    {
      name: 'Dai in Dai Out Productions',
      website: 'https://daiinanddaiout.squarespace.com/',
    },
    {
      name: 'GC Photography',
      website: 'https://gcphotography.com/',
    },
    {
      name: 'TL Wedding Films',
      website: 'https://www.tlweddingfilms.com/',
    },
    {
      name: 'Tushona Monét Photography',
      website: 'https://tushonamonet.com/',
    },
  ],
};

export const rentals = {
  category: 'Rentals',
  vendors: [
    {
      name: 'Vendor 1',
      website: 'https://www.google.com',
    },
    {
      name: 'Vendor 2',
      website: 'https://www.google.com',
    },
    {
      name: 'Vendor 3',
      website: 'https://www.google.com',
    },
    {
      name: 'Vendor 4',
      website: 'https://www.google.com',
    },
    {
      name: 'Vendor 5',
      website: 'https://www.google.com',
    },
  ],
};
