import React, { useState } from 'react'
import VendorRender from './VendorRender'

const VendorDiv = (props) => {
  const [active, setActive] = useState(false)
  const [listMaxHgt, setListMaxHgt] = useState('0')
  const [catPadding, setCatPadding] = useState('0.4rem')

  let styles = {
    vendorCat: {
      paddingBottom: catPadding
    },
    vendorList: {
      maxHeight: listMaxHgt
    }
  }

  const renderDiv = () => {
    if (active) {
      setListMaxHgt('400px')
      setCatPadding('0')

      return(
        <div style={styles.vendorCat} className='vendorCat md:mx-auto md:border md:border-blissGold'>
          <div className='vendorCatTitle space-x-1' onClick={() => toggleActive(active)}>
            <h2 className='text-xl xs:text-2xl sm:text-3xl md:text-4xl'>{props.vendorData.category}</h2>
            <div className='arrow open'>
              <div className='arrowLine'></div>
            </div>
          </div>
          <div style={styles.vendorList} className='vendorList mx-auto text-[17px] xs:text-xl md:text-2xl'>
            <ul className='list-none space-y-1.5'>
              {props.vendorData.vendors.map((vendor, index) => {
                return (
                  <li key={index} className='vendorLi'>
                    <a href={vendor.website} target='_blank' rel='noreferrer' className='vendorLink'>{vendor.name}</a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )
    } else {
      setListMaxHgt('0')
      setCatPadding('0.7rem')

      return(
        <div style={styles.vendorCat} className='vendorCat md:mx-auto md:border md:border-blissGold'>
          <div className='vendorCatTitle space-x-1' onClick={() => toggleActive(active)}>
            <h2 className='text-xl xs:text-2xl sm:text-3xl md:text-4xl'>{props.vendorData.category}</h2>
            <div className='arrow'>
              <div className='arrowLine'></div>
            </div>
          </div>
          <div style={styles.vendorList} className='vendorList mx-auto text-[17px] xs:text-xl md:text-2xl'>
            <ul className='list-none space-y-1.5'>
              {props.vendorData.vendors.map((vendor, index) => {
                return (
                  <li key={index} className='vendorLi'>
                    <a href={vendor.website} target='_blank' rel='noreferrer' className='vendorLink'>{vendor.name}</a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )
    }
  }

  const toggleActive = () => {
    if (active) {
      setActive(false)
    } else {
      setActive(true)
    }
  }

  return (
    <>
      <VendorRender renderDiv={renderDiv} />
    </>
  )
}

export default VendorDiv