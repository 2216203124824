import React, { useEffect, useRef, useState } from 'react'
import { images } from './carouselImgs'

// Used tutorial from https://justacoding.blog/build-a-react-carousel-component-with-infinite-scroll/ as a reference for infinite carousel

const Carousel = () => {
  const [visibleImg, setVisibleImg] = useState(1)
  const [imgs, setImgs] = useState(images)
  const [transition, setTransition] = useState(true)
  const imgID = useRef(null)

  useEffect(() => {
    const clonedImgs = [...images]
    clonedImgs.unshift(clonedImgs[clonedImgs.length - 1])
    clonedImgs.push(clonedImgs[1])
    setImgs(clonedImgs)
  }, [imgs.length])

  useEffect(() => {
    if (imgID.current != null) {
      return
    }
    imgID.current = setInterval(() => {
      setVisibleImg(prevVisibleImg => {
        if (prevVisibleImg + 1 === imgs.length + 2) {
          return 0
        }
        return prevVisibleImg + 1
      })
    }, 4500)

    return () => {
      if (imgID.current) {
        clearInterval(imgID)
      }
    }
  }, [imgs.length])

  useEffect(() => {
    if (visibleImg === imgs.length - 1) {
      setTimeout(() => {
        setTransition(false)
        setVisibleImg(1)
      }, 600)
    }

    if (visibleImg === 1) {
      setTimeout(() => {
        setTransition(true)
      }, 600)
    }

    if (visibleImg === 0) {
      setTimeout(() => {
        setTransition(false)
        setVisibleImg(imgs.length - 2)
      }, 600)

    }

    if (visibleImg === imgs.length - 2) {
      setTimeout(() => {
        setTransition(true)
      }, 600)
    }
  }, [visibleImg, imgs])

  return (
    <div className='carousel'>
      <div className={transition ? 'inner innerWrap' : 'innerWrap'} style={{transform: `translateX(-${visibleImg * 100}%)`}}>
        {imgs.map((image, index) => {
          return (
            <div key={index} className='carouselItem'>
              <img src={image.src} alt={image.alt} className={image.className} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Carousel