import React from 'react'
// import Toast from './Toast'
import Carousel from './Reusable/Carousel'
import { images } from './Reusable/carouselImgs'

const Home = (props) => {
  return (
    <>
      {/* <Toast showToast={props.showToast} setShowToast={props.setShowToast}/> */}
      <div className='relative cursor-default'>
        {/* Carousel & Overlay text */}
        <div className='relative z-10'>
          {props.desktopResolution ? 
            <Carousel /> :
            <div className='carousel'>
              <div className='innerWrap'>
                <div className='carouselItem'>
                  <img src={images[0].src} alt={images[0].alt} className={`${images[0].className}`} />
                </div>
              </div>
            </div> 
          }
          {/* Hero Text */}
          <div className='absolute top-[8%] z-0 text-blissWhite font-workSans text-center text-sm space-y-7 xxs:top-[18%] xs:text-base sm:text-lg sm:top-[23%] md:text-xl lg:text-2xl xl:text-3xl'>
            <p className='mx-auto w-11/12 xs:w-10/12 sm:w-8/12 md:w-7/12 lg:w-5/12 md:font-light'>
              Come HAPPY, leave HAPPIER. Our main focus is making sure you enjoy your booking more than work your booking with Bliss. We take it upon ourselves to provide our clients with the essentials needed to host a successful program/event.
            </p>
            <div className='flex flex-col items-center justify-center'>
              <a href='https://www.honeybook.com/widget/the_bliss_space_237074/cf_id/635ddc1551bb8b031c8a6ec4' className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl' target='_blank' rel='noreferrer'>Book Your Event</a>
            </div>
          </div>
        </div>
        {/* Specifications */}
        <div className='border-t border-t-blissGold py-2 mb-5 space-y-5 lg:space-y-8 lg:py-5'>
          <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>Specifications</h1>
          {/* Capacity */}
          <div className='justify-evenly items-center mx-auto xxs:grid xxs:grid-cols-2'>
            <p className='text-blissWhite font-workSans font-light text-center xssm:text-xl sm:ml-[5rem] md:text-2xl lg:ml-[50%] xl:ml-[60%]'>Capacity</p>
            <div className='invisible absolute left-1/2 h-5 w-[1.5px] bg-blissGold mx-auto xxs:visible md:h-7'></div>
            <p className='text-sm text-blissWhite font-workSans font-extralight text-center xssm:text-lg sm:mr-[5rem] md:text-xl lg:mr-[50%] xl:mr-[60%]'>130 Sitting, 200 Standing</p>
          </div>
          {/* Square Footage */}
          <div className='justify-evenly items-center mx-auto xxs:grid xxs:grid-cols-2'>
            <p className='text-blissWhite font-workSans font-light text-center xssm:text-xl sm:ml-[5rem] md:text-2xl lg:ml-[50%] xl:ml-[60%]'>Square Footage</p>
            <div className='invisible absolute left-1/2 h-5 w-[1.5px] bg-blissGold mx-auto xxs:visible md:h-7'></div>
            <p className='text-sm text-blissWhite font-workSans font-extralight text-center xssm:text-lg sm:mr-[5rem] md:text-xl lg:mr-[50%] xl:mr-[60%]'>2,800 square feet</p>
          </div>
          {/* Inclusive Services */}
          {/* <div className='justify-evenly items-center mx-auto xxs:grid xxs:grid-cols-2 xxs:gap-3'>
            <p className='text-blissWhite font-workSans font-light text-center xxs:ml-[0.5rem] xssm:text-xl sm:ml-[5.5rem] md:text-2xl lg:ml-[52%] xl:ml-[62%]'>Inclusive Services</p>
            <div className='invisible absolute left-1/2 h-5 w-[1.5px] bg-blissGold mx-auto xxs:visible md:h-7'></div>
            <p className='text-sm text-blissWhite font-workSans font-extralight text-center xssm:text-lg sm:mr-[5rem] md:text-xl lg:mr-[50%] xl:mr-[60%]'>Housekeeping, Security</p>
          </div> */}
        </div >
        {/* Floor Plan
        <div className='border-t border-t-blissGold py-2 mb-5 space-y-5 lg:space-y-8 lg:py-5'>
          <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>Floor Plan</h1>
          <div className='w-screen h-40 mx-auto bg-blissGold xs:h-48 xs:w-80 sm:h-60 sm:w-96 md:h-96 md:w-[34rem] lg:h-[30rem] lg:w-[44rem]'></div>
        </div> */}
        {/* Features */}
        <div className='border-t border-t-blissGold py-2 mb- space-y-2 md:space-y-4 md:py-5'>
          <p className='text-blissWhite text-2xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>Features:</p>
          <div className='mx-auto w-fit'>
            <ul className='ml-8 list-disc text-blissWhite font-workSans font-extralight xssm:text-xl'>
              <li>Ample Parking</li>
              <li>Chairs</li>
              <li>Cleaning Supplies</li>
              <li>Free WiFi</li>
              <li>Long Tables</li>
              <li>Round Tables</li>
              <li>Microphone</li>
              <li>Microwave</li>
              <li>On-call Staff</li>
              <li>Projector Unit</li>
              <li>Refrigerator</li>
              <li>Speakers</li>
              {/* <li className=''>Close proximity to <br/>Castleton Square Mall, Costco,<br/> and other major convenience stores.</li> */}
            </ul>
          </div>
        </div>
        {/* Call to action */}
        <div className='border-t border-t-blissGold py-2 mb-5 space-y-3 lg:space-y-5 lg:py-5'>
          <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>Book Your Event</h1>
          <p className='text-blissWhite font-workSans font-extralight text-center mx-auto w-8/12 xs:text-base xs:w-6/12 sm:text-xl sm:w-5/12 md:font-thin md:text-2xl md:w-4/12 lg:text-3xl'>
            Tell us about your event and get a free quote.
          </p>
          <div className='flex flex-col items-center justify-center'>
            <a href='https://www.honeybook.com/widget/the_bliss_space_237074/cf_id/635ddc1551bb8b031c8a6ec4' className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl' target='_blank' rel='noreferrer'>Start Planning</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home