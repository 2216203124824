import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../assets/blissLogo.png'

const Navbar = (props) => {
  return (
    <div className='flex items-center justify-between sticky top-0 left-0 py-6 px-4 z-[100] bg-blissBlack'>
      {/* Logo */}
      <Link to='/' className={props.open ? 'opacity-0 flex items-center space-x-1.5 transition-all ease-in-out duration-500 md:opacity-100 md:ml-11 lg:ml-24 xl:ml-32' : 'opacity-100 flex items-center space-x-1.5 transition-all ease-in-out duration-500 md:ml-11 lg:ml-24 xl:ml-32'}>
        <img src={logo} alt='bliss logo' className='h-[34px] md:h-11' />
        <h1 className='text-blissGold text-3xl font-raleway font-light tracking-widest md:text-5xl md:font-extralight'>Bliss</h1>
      </Link>
      {/* Desktop Navbar */}
      <div className='space-x-3 text-xs pt-2.5 md:mr-11 text-blissGold font-raleway tracking-wide uppercase hidden md:block lg:text-base lg:mr-24 xl:mr-32'>
        <NavLink to='/' className={(navData) => navData.isActive ? 'activeLink' : 'inactiveLink'}>Home</NavLink>
        <a href='https://www.honeybook.com/widget/the_bliss_space_237074/cf_id/635ddc1551bb8b031c8a6ec4' className='inactiveLink' target='_blank' rel='noreferrer'>Booking</a>
        <NavLink to='/gallery' className={(navData) => navData.isActive ? 'activeLink' : 'inactiveLink'}>Gallery</NavLink>
        <NavLink to='/vendors' className={(navData) => navData.isActive ? 'activeLink' : 'inactiveLink'}>Vendors</NavLink>
        <NavLink to='/testimonials' className={(navData) => navData.isActive ? 'activeLink' : 'inactiveLink'}>Testimonials</NavLink>
        <NavLink to='/about' className={(navData) => navData.isActive ? 'activeLink' : 'inactiveLink'}>About</NavLink>
        <NavLink to='/contact' className={(navData) => navData.isActive ? 'activeLink' : 'inactiveLink'}>Contact</NavLink>
      </div>
    </div>
  )
}

export default Navbar