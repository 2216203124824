import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import Home from './components/Home';
import MobileAside from './components/MobileAside';
import MobileMenuBurger from './components/MobileMenuBurger';
import Navbar from './components/Navbar';
import Testimonials from './components/Testimonials';
import Vendors from './components/Vendors';
import Footer from './components/Footer';
import { useMatchMedia } from './components/helpers/useMatchMedia';
// import GalleryRedo from './components/GalleryRedo';

// TODO: Add aria to all interactive elements (buttons, links, toasts, ect.)
// TODO: Increase size of buttons for mobile
// TODO: Ensure text is user-visible while webfonts are loading
// TODO: Front-end cache policy
// TODO: Ensure animations are composited

// ** Navbar.js, MobileMenuBurger.js, MobileAside.js ** \\
// TODO: change div to nav

// ** Footer.js, Contact.js ** \\
// TODO: Give links a discernable name 

function App() {
  const [open, setOpen] = useState(false);
  const desktopResolution = useMatchMedia('(min-width:1024px)', true)
  // const tabletResolution = useMatchMedia('(min-width:640px) and (max-width:1023px)', true)
  // const mobileResolution = useMatchMedia('(max-width:639px)', true)
  const { pathname } = useLocation()

  useEffect(() => {
    scrollToTop()
  }, [pathname])

  const scrollToTop = () => {
    const top = document.getElementById('pageWrap')
    top.scrollIntoView()
  }

  return (
    <>
    <div id='pageWrap'>
        <Navbar open={open} setOpen={setOpen}/>
        <MobileMenuBurger open={open} setOpen={setOpen} />
        <MobileAside open={open} setOpen={setOpen} />
        <Routes>
          <Route path='/' element={<Home desktopResolution={desktopResolution}/>}/>
          <Route path='/gallery' element={<Gallery desktopResolution={desktopResolution}/>}/>
          {/* <Route path='/gallery' element={<GalleryRedo show={3} infiniteLoop={true} desktopResolution={desktopResolution} tabletResolution={tabletResolution} mobileResolution={mobileResolution}/>}/> */}
          <Route path='/vendors' element={<Vendors />}/>
          <Route path='/testimonials' element={<Testimonials/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/contact' element={<Contact/>}/>
        </Routes>
    </div>
    <Footer />
    </>
  );
}

export default App;

  // const [showToast, setShowToast] = useState(false);
  // useEffect(() => {
  //   const toastValue = sessionStorage.getItem('toast')
  //   if (!toastValue) {
  //     const timer = setTimeout(() => {
  //       setShowToast(true)
  //       sessionStorage.setItem('toast', 'show')
  //     }, 2500)
      
  //     return () => clearTimeout(timer)
  //   }

  // }, [])