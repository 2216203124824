import { useState, useEffect } from 'react'

export const useMatchMedia = (mediaQuery, value) => {
  const [isMatching, setIsMatching] = useState(value)

  useEffect(() => {
    const watcher = window.matchMedia(mediaQuery)
    setIsMatching(watcher.matches)
    const listener = (doesMatch) => {
      setIsMatching(doesMatch.matches)
    }

    watcher.addEventListener('change', listener)

    return () => {
      return watcher.removeEventListener('change', listener)
    }
  }, [mediaQuery])

  return isMatching
}