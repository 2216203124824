import React from 'react'

const VendorRender = (props) => {
  return (
    <>
      {props.renderDiv()}
    </>
  )
}

export default VendorRender