import React from 'react'
import { testimonials } from './Reusable/testimonials'
import { BsDot } from 'react-icons/bs'

const Testimonials = (props) => {
  // Removes toast if it wasn't closed when user was on homepage
  // useEffect(() => {
  //   const pathname = window.location.pathname
  //   if (props.showToast && pathname !== '/') {
  //     props.setShowToast(false)
  //   }
  // })

  return (
    <div>
      {/* Header */}
      <div className='border-t border-t-blissGold py-2 space-y-2 mt- md:space md:py-5'>
        <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin xs:text-4xl sm:text-5xl mt-5'>Testimonials</h1>
        {/* <p className='text-blissWhite font-workSans font-extralight text-center px-[2%] mx-auto w-11/12 xs:text-lg xs:w-10/12 sm:text-xl sm:w-8/12 md:font-thin md:text-2xl md:w-7/12 lg:text-3xl lg:w-5/12'>
          Coming soon
        </p> */}
        <p className='text-center text-blissWhite font-workSans font-extralight text-xl pb-5 border-b border-b-blissGold sm:text-xl md:text-2xl lg:text-3xl'>
          Take a look at our <br/> clients' experience.
        </p>
      </div>
      {testimonials.map((testimony) => {
        return (
          <div key={testimony.initials} className='py-2 space-y-2 mb-2 md:space-y-4 md:py-5'>
          <p className='text-center mx-auto text-blissWhite font-workSans font-extralight xssm:text-xl w-11/12 xs:w-10/12 sm:w-8/12 md:w-7/12 lg:w-5/12'>{testimony.testimonial}</p>
          <p className={testimony.className}><BsDot/> {testimony.initials}</p>
        </div>
        )
      })}
      <div className='border-t border-t-blissGold py-2 mb-5 space-y-3 lg:space-y-5 lg:py-5'>
        <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>Book Your Event</h1>
        <p className='text-blissWhite font-workSans font-extralight text-center mx-auto w-8/12 xs:text-base xs:w-6/12 sm:text-xl sm:w-5/12 md:font-thin md:text-2xl md:w-4/12 lg:text-3xl'>
          Let us help you <br/> host the perfect event.
        </p>
        <div className='flex flex-col items-center justify-center'>
          <a href='https://www.honeybook.com/widget/the_bliss_space_237074/cf_id/635ddc1551bb8b031c8a6ec4' className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl' target='_blank' rel='noreferrer'>Start Planning</a>
        </div>
      </div>
    </div>
  )
}

export default Testimonials