import React from 'react'
import VendorDiv from './Reusable/VendorDiv.js'
import { caterers, decorators, djs, eventPlanners, hotels, photoVideo } from './Reusable/vendorInfo.js'

const Vendors = (props) => {
  // Removes toast if it wasn't closed when user was on homepage
  // useEffect(() => {
  //   const pathname = window.location.pathname
  //   if (props.showToast && pathname !== '/') {
  //     props.setShowToast(false)
  //   }
  // })

  return (
    <div className='border-t border-t-blissGold py-3 md:py-5'>
      <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin xs:text-4xl sm:text-5xl'>Vendors</h1>
      <p className='text-blissWhite font-workSans font-extralight text-center px-[2%] mb-3 mx-auto w-11/12 text-lg xs:w-10/12 xs:text-xl sm:text-xl sm:w-8/12 md:font-thin md:text-2xl md:w-7/12 md:mb-6 lg:text-3xl lg:w-5/12'>
        Our curated list of<br/> partnered vendors.
      </p>
      {/* Caterers */}
      <div className='md:shadow-[3px_3px_7px_1px_rgba(197,169,115,0.1),-3px_-3px_7px_1px_rgba(197,169,115,0.1)] md:mb-5 md:mx-auto md:border md:border-blissGold md:rounded md:w-9/12 lg:w-7/12 xl:w-6/12 2xl:w-4/12'>
        {/* <VendorDiv vendorData={bartenders} /> */}
        <VendorDiv vendorData={caterers} />
        <VendorDiv vendorData={decorators} />
        <VendorDiv vendorData={djs} />
        <VendorDiv vendorData={eventPlanners} />
        <VendorDiv vendorData={hotels} />
        <VendorDiv vendorData={photoVideo} />
        {/* <VendorDiv vendorData={rentals} /> */}
      </div>
      <div className='border-t border-t-blissGold py-2 space-y-3 lg:space-y-5 lg:py-5'>
        <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>Book Your Event</h1>
        <p className='text-blissWhite font-workSans font-extralight text-center mx-auto w-8/12 xs:text-base xs:w-6/12 sm:text-xl sm:w-5/12 md:font-thin md:text-2xl md:w-4/12 lg:text-3xl'>
          Let us and our partnered vendors help you host the perfect event.
        </p>
        <div className='flex flex-col items-center justify-center'>
          <a href='https://www.honeybook.com/widget/the_bliss_space_237074/cf_id/635ddc1551bb8b031c8a6ec4' className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl' target='_blank' rel='noreferrer'>Start Planning</a>
        </div>
      </div>
    </div>
  )
}

export default Vendors