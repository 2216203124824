import React from 'react'
import { BsDot } from 'react-icons/bs'

const Contact = (props) => {
  // Removes toast if it wasn't closed when user was on homepage
  // useEffect(() => {
  //   const pathname = window.location.pathname
  //   if (props.showToast && pathname !== '/') {
  //     props.setShowToast(false)
  //   }
  // })

  return (
    <div className='relative'>
      <div className='border-t border-t-blissGold py-4 mb-5 space-y-5 lg:space-y-8 lg:py-5'>
        <h1 className='text-blissWhite text-2xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>Contact Us</h1>
        {/* Operating Hours */}
        <div className='justify-evenly items-center mx-auto xxs:grid xxs:grid-cols-2'>
          <p className='text-blissWhite font-workSans font-light text-center xssm:text-xl sm:ml-[5rem] md:text-2xl xssm:ml-[25%] lg:ml-[50%] xl:ml-[60%]'>Calling Hours</p>
          <div className='invisible absolute left-1/2 h-[27px] w-[1.5px] bg-blissGold mx-auto xxs:visible md:h-9'></div>
          <div>
            <p className='text-sm text-blissWhite font-workSans font-extralight text-center xxs:text-[13.5px] xssm:text-lg sm:mr-[5rem] md:text-xl xl:text-[19px] lg:mr-[50%] xl:mr-[60%]'>Monday - Friday</p>
            <p className='text-sm text-blissWhite font-workSans font-extralight text-center xxs:text-[13.5px] xssm:text-lg sm:mr-[5rem] md:text-xl xl:text-[19px] lg:mr-[50%] xl:mr-[60%]'>9 A.M. - 5 P.M.</p>
          </div>
        </div>
        {/* General Inquiries */}
        <div className='justify-evenly items-center mx-auto xxs:grid xxs:grid-cols-2'>
          <p className='text-blissWhite font-workSans font-light text-center xssm:text-xl sm:ml-[5rem] md:text-2xl xssm:ml-[25%] lg:ml-[50%] xl:ml-[60%]'>General Inquiries</p>
          <div className='invisible absolute left-1/2 h-[27px] xssm:h-9 w-[1.5px] bg-blissGold mx-auto xxs:visible md:h-9'></div>
          <div className='flex flex-col items-center justify-center'>
            {/* Email */}
            <a href='mailto:theblissspacellc@gmail.com' className='text-sm text-blissWhite font-workSans font-extralight text-center xxs:text-[13.5px] xssm:text-lg sm:mr-[5rem] md:text-xl xl:text-[19px] lg:mr-[50%] xl:mr-[60%]'>theblissspacellc@gmail.com</a>
            {/* Phone Number */}
            <a href='tel:+14632242850' className='flex items-center text-sm text-blissWhite font-workSans font-extralight text-center xxs:text-[13.5px] xssm:text-lg sm:mr-[5rem] md:text-xl xl:text-[19px] lg:mr-[50%] xl:mr-[60%]'>463 <BsDot/> 224<BsDot/>2850</a>
          </div>
        </div>
        {/* Location */}
        <div className='justify-evenly items-center mx-auto xxs:grid xxs:grid-cols-2'>
          <p className='text-blissWhite font-workSans font-light text-center xssm:text-xl sm:ml-[5rem] md:text-2xl xssm:ml-[25%] lg:ml-[50%] xl:ml-[60%]'>Location</p>
          <div className='invisible absolute left-1/2 h-[27px] xssm:h-9 w-[1.5px] bg-blissGold mx-auto xxs:visible md:h-9'></div>
          <div className='flex flex-col items-center justify-center'>
            <a href='https://www.google.com/maps/search/?api=1&query=8445+Castleton+Corner+Dr%2C+Indianapolis%2C+IN+46250' target='blank' className='text-sm text-blissWhite font-workSans font-extralight text-center xxs:text-[13.5px] xssm:text-lg sm:mr-[5rem] md:text-xl xl:text-[19px] lg:mr-[50%] xl:mr-[60%]'>8445 Castleton Corner Dr<br/> Indianapolis, IN 46250</a>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center'>
          <a href='mailto:theblissspacellc@gmail.com' className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl'>Schedule a Tour</a>
        </div>
        <div className='w-fit mx-auto'>
          <iframe title='Bliss Event Space' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.4348124980775!2d-86.07279118446235!3d39.90928467942722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886b4d3686fcdc8d%3A0x38c00b43df371391!2s8445%20Castleton%20Corner%20Dr%2C%20Indianapolis%2C%20IN%2046250!5e0!3m2!1sen!2sus!4v1666891010052!5m2!1sen!2sus" className='w-64 h-48 xs:w-96 xs:h-48 sm:w-[500px] sm:h-[300px]' loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div> 
  )
}

export default Contact