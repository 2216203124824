import React from 'react'
import { useNavigate } from 'react-router-dom'

const About = (props) => {
  const navigate = useNavigate()

  const contact = () => {
    navigate('/contact')
  }

  // Removes toast if it wasn't closed when user was on homepage
  // useEffect(() => {
  //   const pathname = window.location.pathname
  //   if (props.showToast && pathname !== '/') {
  //     props.setShowToast(false)
  //   }
  // })

  return (
    <>
      <div className='border-t border-t-blissGold space-y-2 md:space-y-3 mb-5'>
        <h1 className='text-blissWhite text-3xl text-center font-raleway font-thin xs:text-4xl sm:text-5xl mt-5'>About Us</h1>
        <p className='text-blissWhite font-workSans font-extralight text-center px-[2%] mx-auto w-11/12 xs:text-lg xs:w-10/12 sm:text-xl sm:w-8/12 lg:w-6/12  md:font-thin md:text-xl lg:text-2xl'>
        We at Bliss Event Space strive to create an “arrive happy, and leave happier” environment for all clients that use our space. Our primary expertise is customer service and care. From celebratory birthday parties, baby showers, and open houses, to the impactful networking events, open mics, and company seminars, etc, we take pride in providing all clientele the essential tools and resources needed to take as much pressure off them in preparation to enjoy their event.
        </p>
      </div>
      {/* Events Provided */}
        <div className='border-t border-t-blissGold py-2 space-y-2 mb-2 md:space-y-4 md:py-5'>
          <h2 className='text-blissWhite text-3xl xs:text-4xl text-center font-raleway font-thin sm:text-5xl'>What We Do</h2>
          <div className='space-y-3 grid grid-cols-1 sm:grid-cols-2 sm:space-y-0'>
            <div className='mx-auto w-fit md:ml-auto md:mr-5 lg:mr-9 xl:mr-11'>
              <h4 className='text-center text-blissWhite font-workSans font-extralight text-xl xssm:text-2xl'>Events We Host</h4>
              <ul className='ml-8 list-disc text-blissWhite font-workSans font-extralight xssm:text-xl'>
                <li>Baby Showers</li>
                <li>Birthday Celebrations</li>
                <li>Corporate Events</li>
                <li>Graduation Celebrations</li>
                <li>Holiday Celebrations</li>
                <li>Receptions</li>
                <li>Religious Gatherings</li>
                <li>Weddings</li>
                <li>Vendor Fairs</li>
              </ul>
            </div>
            <div className='mx-auto w-fit md:mr-auto md:ml-5 lg:ml-9 xl:ml-11'>
              <h4 className='text-center text-blissWhite font-workSans font-extralight text-xl xssm:text-2xl'>Daily/Monthly Space Rentals</h4>
              <ul className='ml-14 list-disc text-blissWhite font-workSans font-extralight xssm:text-xl'>
                <li>Corporate Meetings</li>
                <li>Health Instructors/Teachers</li>
                <li>Mary Kay Consultants</li>
                <li>Nutritionists</li>
                <li>Office/Meeting Space</li>
                <li>Personal Trainers</li>
                <li>Podcasters</li>
                <li>Small Businesses</li>
                <li>Yoga Instructors</li>
              </ul>
            </div>
          </div>
        </div>
      <div className='border-t border-t-blissGold py-2 mb-5 space-y-3 lg:space-y-5 lg:py-5'>
        <h2 className='text-blissWhite text-3xl text-center font-raleway font-thin sm:text-4xl md:text-5xl'>Have Questions?</h2>
        <p className='text-blissWhite font-workSans font-extralight text-center mx-auto w-11/12 xs:text-base xs:w-10/12 sm:text-xl sm:w-8/12 md:font-thin md:text-2xl md:w-7/12 lg:text-3xl lg:w-5/12'>
          Reach out to us to get more information about our services & other types of events.
        </p>
        <button onClick={() => contact()} className='text-sm text-blissWhite font-workSans bg-blissGold border-[0.5px] border-blissGold px-2 py-1 rounded font-light drop-shadow-md block mx-auto hover:text-blissGold hover:bg-blissBlack hover:scale-110 transition-all ease-in-out duration-[400ms] md:font-extralight md:text-lg lg:text-xl xl:text-2xl'>Contact Us</button>
      </div>
    </>
  )
}

export default About