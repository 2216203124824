import React from 'react'

const MobileMenuBurger = (props) => {
  return (
    <>
      {/* Used to close mobile aside because top of aside is below navbar */}
      <div onClick={() => props.setOpen(!props.open)} className={props.open ? 'block absolute z-[100] w-screen h-[5.25rem] top-0 left-0 cursor-pointer md:hidden' : 'hidden absolute top-0 left-0 z-[100] w-screen h-[5.25rem] cursor-pointer md:hidden'}></div>
      {/* Menu hamburger with animation */}
      <div onClick={() => props.setOpen(!props.open)} className={props.open ? 'menuBtn open' : 'menuBtn'}>
        <div className='menuBtnBurger'></div>
      </div>
    </>
  )
}

export default MobileMenuBurger