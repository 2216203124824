const testimonialCSS = 'flex items-center justify-center text-blissWhite font-workSans font-extralight xssm:text-xl w-11/12 mx-auto border-b border-b-blissLightGold pb-2.5 xs:w-10/12 sm:w-8/12 md:w-7/12 lg:w-5/12'

export const testimonials = [
  {
    testimonial: `The space is great! Can be transformed to fit any party you’re hoping to host here! The staff is knowledgeable and helpful with any questions and gets back to you quickly. Location is great and plenty of parking because of location.`,
    initials: 'N. M.',
    className: testimonialCSS
  },
  {
    testimonial: 'This is a beautiful space! In a great central area, and the owner is wonderful to work with!',
    initials: 'L. J.',
    className: testimonialCSS
  },
  {
    testimonial: 'Lovely hospitality by and from the owner. Would highly recommend. My family & I had a lovely time!',
    initials: 'D. JB.',
    className: testimonialCSS
  },
  {
    testimonial: 'Absolutely a wonderful place to hold an event! Nice location and a wonderful owner to work with you.',
    initials: 'J. M.',
    className: testimonialCSS
  },
  {
    testimonial: 'I know the owner of the event space personally and I see this place going very far. Super professional and accommodating! 10/10 definitely recommend',
    initials: 'H. W.',
    className: testimonialCSS
  },
  {
    testimonial: 'Great hospitality, very welcoming & very affordable!',
    initials: 'M. O.',
    className: testimonialCSS
  },
  {
    testimonial: 'A perfect place to cover all your party needs, in a great location ',
    initials: 'S. B.',
    className: testimonialCSS
  },
  {
    testimonial: 'Great management. Nice and easy to work with. Amazing space',
    initials: 'A. A.',
    className: testimonialCSS
  },
  {
    testimonial: 'One the best spaces available hands down! The owner is easy to work with and gives us peace of mind!!',
    initials: 'A.',
    className: 'flex items-center justify-center text-blissWhite font-workSans font-extralight xssm:text-xl w-11/12 mx-auto xs:w-10/12 sm:w-8/12 md:w-7/12 lg:w-5/12'
  },
  // {
  //   testimonial: ``,
  //   initials: ''
  // },
]