import blissMock1 from '../../assets/blissmock-1.webp';
import blissMock4 from '../../assets/blissmock-4.webp';
import blissMock6 from '../../assets/blissmock-6.webp';
import blissMock8 from '../../assets/blissmock-8.webp';
import blissMock19 from '../../assets/blissmock-19.webp';
import blissMock24 from '../../assets/blissmock-24.webp';
import blissMock27 from '../../assets/blissmock-27.webp';
import blissMock29 from '../../assets/blissmock-29.webp';
import blissMock34 from '../../assets/blissmock-34.webp';
import blissMock39 from '../../assets/blissmock-39.webp';
import blissMock41 from '../../assets/blissmock-41.webp';
import blissMock45 from '../../assets/blissmock-45.webp';
import blissSpace from '../../assets/blissSpace.webp';
import blissSpace2 from '../../assets/blissSpace2.webp';
import blissWedding from '../../assets/blissWedding.webp';
import blissBday from '../../assets/blissBday.webp';
import blissBday2 from '../../assets/blissBday2.webp';
import blissBday3 from '../../assets/blissBday3.webp';
import blissBday4 from '../../assets/blissBday4.webp';
import blissBday5 from '../../assets/blissBday5.webp';
import blissBday6 from '../../assets/blissBday6.webp';
import blissBday7 from '../../assets/blissBday7.webp';
import blissBday8 from '../../assets/blissBday8.webp';
import blissBday9 from '../../assets/blissBday9.webp';
import blissBday10 from '../../assets/blissBday10.webp';
import blissBday11 from '../../assets/blissBday11.webp';
import blissBday12 from '../../assets/blissBday12.webp';
import blissBday13 from '../../assets/blissBday13.webp';
import blissBday14 from '../../assets/blissBday14.webp';
import blissBday15 from '../../assets/blissBday15.webp';
import blissBday16 from '../../assets/blissBday16.webp';
import blissBday17 from '../../assets/blissBday17.webp';
import blissBday18 from '../../assets/blissBday18.webp';
import mockBabyShower from '../../assets/IMG_5688.webp';
import mockWedding from '../../assets/IMG_5696.webp';
import mockTableSet1 from '../../assets/IMG_8780.webp';
import mockTableSet2 from '../../assets/IMG_8784.webp';

// ** Home Page Carousel Images & Class ** \\

const standardCSS = 'h-full w-full object-cover absolute inset-y-0 my-auto';

export const images = [
  {
    name: 'Bliss Mock Event',
    src: blissMock8,
    alt: 'Table setting with a white table cloth, flowers, candles, dinnerware and clear chairs',
    className: standardCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock41,
    alt: 'Group of people networking at an event',
    className:
      'h-full w-full object-cover absolute bottom-1/2 top-1/2 my-auto md:h-fit md:top-[-5rem] md:bottom-auto',
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock29,
    alt: 'Close up picture of a table setting featuring champagne flutes, dinnerware, flowers and candles',
    className: standardCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock34,
    alt: 'Table set with dishware for an event',
    className: standardCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock45,
    alt: 'Room of tables and chairs decorated for a wedding reception',
    className:
      'h-full w-full object-cover absolute bottom-1/2 top-1/2 my-auto md:h-fit md:top-auto md:bottom-[-5rem]',
  },
];

// ** Gallery Images & Class ** \\

const galleryCSS = 'h-72 w-full object-cover my-auto xssm:h-80 lg:h-96';

export const galleryImgs = [
  {
    name: 'Bliss Mock Event',
    src: blissMock1,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock6,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock4,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock8,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock19,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock24,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock27,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock29,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock34,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock39,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock41,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: blissMock45,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Open Space',
    src: blissSpace,
    alt: 'Bliss Open Space',
    className: galleryCSS,
  },
  {
    name: 'Bliss Open Space',
    src: blissSpace2,
    alt: 'Bliss Open Space',
    className: galleryCSS,
  },
  {
    name: 'Bliss Wedding',
    src: blissWedding,
    alt: 'Bliss Wedding',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday10,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday2,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday3,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday4,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday5,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday6,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday7,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday8,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday9,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday11,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday13,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday12,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday14,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday16,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday17,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday18,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Birthday',
    src: blissBday15,
    alt: 'Bliss Birthday',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: mockBabyShower,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: mockWedding,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: mockTableSet1,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
  {
    name: 'Bliss Mock Event',
    src: mockTableSet2,
    alt: 'Bliss Mock Event',
    className: galleryCSS,
  },
];
