import React from 'react'
import { Link } from 'react-router-dom'

const MobileAside = (props) => {
  return (
    <div onClick={() => props.setOpen(!props.open)} className={props.open ? 'block opacity-100 top-[5.25rem] fixed h-full w-full transition-all ease-in-out duration-500 z-[99] bg-blissBlack text-blissGold cursor-pointer md:hidden' : 'block opacity-0 top-[-100%] fixed h-full w-full transition-all ease-in-out duration-500 z-[99] md:hidden'}>
      <div className='grid gap-px font-raleway text-center text-2xl font-light'>
        <Link to='/' onClick={() => props.setOpen(!props.open)} className={window.location.pathname === '/' ? 'h-fit py-3 bg-blissLightGold text-blissBlack transition-all ease-in-out duration-500' : 'text-blissGold h-fit py-3 transition-all ease-in-out duration-500 mobileHover'}>Home</Link>
        <a href='https://www.honeybook.com/widget/the_bliss_space_237074/cf_id/635ddc1551bb8b031c8a6ec4' onClick={() => props.setOpen(!props.open)} className='text-blissGold h-fit py-3 transition-all ease-in-out duration-500 mobileHover' target='_blank' rel='noreferrer'>Booking</a>
        <Link to='/gallery' onClick={() => props.setOpen(!props.open)} className={window.location.pathname === '/gallery' ? 'h-fit py-3 bg-blissLightGold text-blissBlack transition-all ease-in-out duration-500' : 'text-blissGold h-fit py-3 transition-all ease-in-out duration-500 mobileHover'}>Gallery</Link>
        <Link to='/vendors' onClick={() => props.setOpen(!props.open)} className={window.location.pathname === '/vendors' ? 'h-fit py-3 bg-blissLightGold text-blissBlack transition-all ease-in-out duration-500' : 'text-blissGold h-fit py-3 transition-all ease-in-out duration-500 mobileHover'}>Vendors</Link>
        <Link to='/testimonials' onClick={() => props.setOpen(!props.open)} className={window.location.pathname === '/testimonials' ? 'h-fit py-3 bg-blissLightGold text-blissBlack transition-all ease-in-out duration-500' : 'text-blissGold h-fit py-3 transition-all ease-in-out duration-500 mobileHover'}>Testimonials</Link>
        <Link to='/about' onClick={() => props.setOpen(!props.open)} className={window.location.pathname === '/about' ? 'h-fit py-3 bg-blissLightGold text-blissBlack transition-all ease-in-out duration-500' : 'text-blissGold h-fit py-3 transition-all ease-in-out duration-500 mobileHover'}>About</Link>
        <Link to='/contact' onClick={() => props.setOpen(!props.open)} className={window.location.pathname === '/contact' ? 'h-fit py-3 bg-blissLightGold text-blissBlack transition-all ease-in-out duration-500' : 'text-blissGold h-fit py-3 transition-all ease-in-out duration-500 mobileHover'}>Contact</Link>
      </div>
    </div>
  )
}

export default MobileAside